import { Link } from 'react-router-dom';
import './Home.css'
import Projects from './Projects.js'

import './ScrollDown.css'

function Home()
{
    {document.title = 'Rohit Patidar'}
    return(
        <>
        <div className="Home">
            <div className="top gradient">
            <div className="top-content">
            <div className="introduction-section">
                <div className="intro-title-container">
                <p className="intro-title">I am <br /> <div className='what-i-do'></div> </p>
                <img className='hi-img' height="50px" src="wave.png" alt="" />
                </div>
                <div className="subtitle-container">
                    <div className="intro-subtitle">Hi! I am Rohit Patidar. <br />I am passionated Front-end React Developer based in Madhya Pradesh, India. 📍</div>
                    <Link to='/about' style={{marginTop: '20px', backgroundColor: 'rgba(0,0,0,0)', border: 0, color: '#41A6FF', fontWeight: '500', textDecoration: 'none'}}>Know More About Me</Link>
                </div>
            </div>
            <div className="intro-image-container">
                <img className='intro-image' height="300px" src="rb.jpg" alt="" draggable={false} />
            </div>
            </div>
            </div>

            <a href="#">
            <div id="mouse-scroll">
            <div class="mouse">
            <div class="mouse-in"></div>
            </div>
            <div>
            <span class="down-arrow-1"></span>
            <span class="down-arrow-2"></span>
            <span class="down-arrow-3"></span>
            </div>
            </div>
            </a>

        </div>
        <Projects/>
        </>
    );
}

export default Home;