// import logo from './logo.svg';
import './App.css';

//All Pages
import Navbar from './components/Navbar';
import About from './components/About';
import Home from './components/Home';
import Error from './components/Error';

//React Router System
import { Route, createBrowserRouter, createRoutesFromElements, Outlet, RouterProvider, Routes, BrowserRouter } from "react-router-dom";
import Footer from './components/Footer';

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Root/>}>
          <Route index element={<Home/>} />
          <Route path="/about" element={<About/>} />
        </Route>
        <Route path="*" element={<Error/>} />
      </Routes>
    </BrowserRouter>
  );
}

const Root = () => {
  return (
    <>
      <div>
      <Navbar/>
      <Outlet/>
      <Footer/>
      </div>
    </>
  );
}

export default App;
