import './Projects.css'
import {Link} from 'react-router-dom'

function Projects()
{
    return(
        <>
        <div className="projects">
            <div className="projects-heading">PROJECTS</div>
            <div className="projects-container">
                <div id="card" className="p1">
                    <img className="project-img" src="P1S.png" alt="" />
                    <div className="project-title">Whatsapp Clone | Social Media</div>
                    <p className="project-description">Whatsapp clone website</p>
                    <Link to='https://group-hive.netlify.app/' target='_blank'><button className="project-btn">Live Demo</button></Link>
                </div>
                
                <div id="card" className="p2">
                    <img className="project-img" src="P2S.png" alt="" />
                    <div className="project-title">Youtube Clone</div>
                    <p className="project-description">Youtube clone created with Youtube V3 API to fetch videos and search results and channel details etc.</p>
                    <Link to='https://better-youtube.netlify.app' target='_blank'><button className="project-btn">Live Demo</button></Link>
                </div>

                <div id="card" className="p3">
                    <img className="project-img" src="P3S.png" alt="" />
                    <div className="project-title">Digital Marketing || Landing Page</div>
                    <p className="project-description">Landing page for digital marketing company and other services like web development.</p>
                    <Link to='https://synapse-media.netlify.app/' target='_blank'><button className="project-btn">Live Demo</button></Link>
                </div>

                <div id="card" className="p1">
                    <img className="project-img" src="P4S.png" alt="" />
                    <div className="project-title">Ethereum Transaction || Blockchain Technology</div>
                    <p className="project-description">With the help of this website you can send ethereum crypto currency to anyone.</p>
                    <Link to='https://ethereum-krypt.netlify.app/' target='_blank'><button className="project-btn">Live Demo</button></Link>
                </div>

                <div id="card" className="p1">
                    <img className="project-img" src="P5S.png" alt="" />
                    <div className="project-title">Article Summarizer || Ai Based</div>
                    <p className="project-description">With the help of this website you can summarize any article by just providing it's link.</p>
                    <Link to='https://summarizer-x.netlify.app/' target='_blank'><button className="project-btn">Live Demo</button></Link>
                </div>

            </div>
        </div>
        </>
    );
}

export default Projects;