import './Navbar.css'
import {Link} from 'react-router-dom'

//Loading
import React, { useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

function Navbar()
{
    const [progress, setProgress] = useState(0)

    window.addEventListener('scroll',(e)=>{
        const nav = document.querySelector('.navbar');
        if(window.pageYOffset>0){
          nav.classList.add("navbar-scrolled");
        }else{
          nav.classList.remove("navbar-scrolled");
        }
    });

    return(
        <>
        <LoadingBar color='#147efb' progress={progress + .001} onLoaderFinished={() => setProgress(0)} />
        <div className="navbar">
            <Link className="logo-container" to="/" onClick={() => setProgress(100)}><div>Rohit Patidar</div></Link>
            <div className="buttons-container">
                <Link to='/about'><button className='nav-btn' onClick={() => setProgress(100)}>About me</button></Link>
                <Link><button className="nav-btn">Hire</button></Link>
            </div>
        </div>
        </>
    );
}

export default Navbar;