import './Footer.css'

// import Ad from './Ad'

function Footer()
{
    return(
        <>
        <div className="footer">
            <div className="copyright">Copyright © 2023. All rights reserved</div>
            <div className="social-handles">
                <a className='discord' href='https://discord.gg/ZyT7CQrJuf'>
                    <img height='25px' src="Discord.png" alt="" />
                </a>
            </div>
        </div>
        {/* <Ad/> */}
        </>
    );
}

export default Footer;