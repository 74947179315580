import './About.css'
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function About()
{
    {document.title = 'About'}
    return(
        <>
        <section class="about" id="about">
            <div class="container">
            <div class="about-content">
                <div class="img-side">
                <img src="about-us.png" alt="emoji" class="work-emoji" />
                </div>
                <div class="text-side">
                    <h4>About me</h4>
                    <h3>I am dedicated Front-end Developers <br/> based in Madhya Pradesh, India 📍</h3>
                    <p>As a Junior Front-End Developer, I possess an impressive arsenal of skills in HTML, CSS, JavaScript, React, Tailwind etc. I excel in designing and maintaining responsive websites that offer a smooth user experience. Our expertise lies in crafting dynamic, engaging interfaces through writing clean and optimized code and utilizing cutting-edge development tools and techniques. We are team player who thrives in collaborating with cross-functional teams to produce outstanding web applications.</p>
                </div>
            </div>
            </div>
            {/* <div className="payment-section">
            <PayPalScriptProvider
                options={{ 
                "client-id":
                    "AYfwxho8r99_rgNb-pFqo7Xrp_wnw3iELmCSTTSttQi-_ZdyCNa6ZELu0JT4Iz2WOjXmzF-VqQ-jEq_T" 
                }}>
            <PayPalButtons 
            createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                value: "12.99",
                            },
                        },
                    ],
                });
            }}
            onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                    const name = details.payer.name.given_name;
                    alert(`Transaction completed by ${name}`);
                });
            }}
            />
            </PayPalScriptProvider>
            </div> */}
        </section>
        </>
    );
}

export default About;