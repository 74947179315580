import './Error.css'

function Error()
{
    return(
        <>
        <div className="error-page">
            <div className="error-container">
                <div className="error-content">
                    <div className="error-heading">OOPS!</div>
                    <div className="error-description">You weren't supposed to see this</div>
                </div>
                <div className="img-container">
                    <img className="error-image" src="error-404.png" alt="" />
                </div>
            </div>
        </div>
        </>
    );
}

export default Error;